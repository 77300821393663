<template>
  <div>
    <div class="text-center mt-4" v-if="loader">
      <Loader
        :size="'big'"
      />
    </div>
    <div class="mt-4" v-else>
      <div class="pdf-wrapper col-md-8 mx-auto">
        <template v-if="src">
          <div class="mb-3 text-center">
            <Button
              class="btn btn-primary"
              @click.prevent="$refs.pdfComponent.print()"
            >
              {{ $t('download') }}
            </Button>
          </div>
          <pdf ref="pdfComponent" :src="src"></pdf>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

import OrdersService from '@/modules/orders/services/orders-service'

import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'
import pdf from 'vue-pdf'

export default {
  components: {
    Button,
    pdf,
    Loader
  },
  data () {
    return {
      loader: false,
      src: ''
    }
  },
  created () {
    this.getPDF()
  },
  methods: {
    /**
     * Get PDF from API
     */
    async getPDF () {
      this.loader = true
      try {
        const data = await OrdersService.downloadNFSe(this.$route.params.id)
        var raw = window.atob(data)
        var rawLength = raw.length
        var array = new Uint8Array(new ArrayBuffer(rawLength))

        for (var i = 0; i < rawLength; i++) {
          array[i] = raw.charCodeAt(i)
        }
        this.src = array
        // this.src = JSON.parse(data)
      } catch (error) {
        console.log(error)
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
